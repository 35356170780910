// @flow
import { getLogger } from "common/logger";

type ReadFileData = {
    file: Object,
    index: number,
    count: number,
    readAs: string,
    readResolve: Function,
    readReject: Function,
};

const logger = getLogger();

export const READ_AS = {
	URL: "DATA_URL", // default: data url
	BUFFER: "ARRAY_BUFFER", // array buffer
};

export default () => {
	const files = [];
	const reader = new FileReader();

	let lastReadFileData = null;

	const onReadSuccess = () => {
		if (lastReadFileData) { // for flow :(
			lastReadFileData.readResolve({
				file: lastReadFileData.file,
				index: lastReadFileData.index,
				count: lastReadFileData.count,
				result: reader.result,
			});
		}

		handleReadFinish();
	};

	const handleReadFinish = () => {
		lastReadFileData = null;

		reader.removeEventListener("load", onReadSuccess, false);
		reader.removeEventListener("error", onReadError, false);

		if (files.length) {
			readFile(files.shift()); // continue to the next file
		}
	};

	const onReadError = () => {
		logger.log("[utils.fileReader]: failed to read file", reader.error);

		if (lastReadFileData) { // for flow :(
			lastReadFileData.readReject(reader.error);
		}

		handleReadFinish();
	};

	const readFile = (data: ReadFileData) => {
		lastReadFileData = data;

		reader.addEventListener("load", onReadSuccess, false);
		reader.addEventListener("error", onReadError, false);

		if (data.readAs === READ_AS.BUFFER) {
			reader.readAsArrayBuffer(data.file);
		} else {
			reader.readAsDataURL(data.file);
		}
	};

	const read = (readAs: string, file: File, index: number = 0, count: number = 0) : Promise<Object> => new Promise((resolve, reject) => {
		const readData = {
			file,
			index,
			count,
			readAs,
			readResolve: resolve,
			readReject: reject,
		};

		if (reader.readyState !== 1) { // if not already loading
			readFile(readData);
		} else {
			files.push(readData); // queue for later
		}
	});

	const readAsBuffer = (file: File, index: number = 0, count: number = 0) => read(READ_AS.BUFFER, file, index, count);

	const readAsUrl = (file: File, index: number = 0, count: number = 0) => read(READ_AS.URL, file, index, count);

	return {
		readAsUrl,
		readAsBuffer,
	};
};
